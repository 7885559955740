<template>
  <div id="container" class="day-mode">
    <aside>
      <div class="link-box">
        <div class="avatar-box">
          <img src="../../assets/avatar.jpg" alt="avatar" class="avatar" />
          <p>Apollo</p>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'home' }"
        >
          <router-link to="/home">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="{ 'aside-icon': $route.name === 'home' }"
            >
              <path
                d="M9 18V42H39V18L24 6L9 18Z"
                fill="none"
                stroke="#2c3e50"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 29V42H29V29H19Z"
                fill="none"
                stroke="#2c3e50"
                stroke-width="4"
                stroke-linejoin="round"
              />
              <path
                d="M9 42H39"
                stroke="#2c3e50"
                stroke-width="4"
                stroke-linecap="round"
              /></svg
          ></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'tools' }"
        >
          <router-link to="/tools">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="{ 'aside-icon': $route.name === 'tools' }"
            >
              <path
                d="M8 25V38C8 39.6569 9.34315 41 11 41H37C38.6569 41 40 39.6569 40 38V25"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 15C5 13.8954 5.89543 13 7 13H41C42.1046 13 43 13.8954 43 15V23C43 24.1046 42.1046 25 41 25H7C5.89543 25 5 24.1046 5 23V15Z"
                fill="none"
                stroke="#000"
                stroke-width="4"
                stroke-linejoin="round"
              />
              <path
                d="M31 13V9C31 7.89543 30.1046 7 29 7H19C17.8954 7 17 7.89543 17 9V13"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 23V29"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33 23V29"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
          ></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'wzkAdmin' }"
        >
          <router-link to="/wzkAdmin">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="{ 'aside-icon': $route.name === 'wzkAdmin' }"
            >
              <path
                d="M24 20C27.866 20 31 16.866 31 13C31 9.13401 27.866 6 24 6C20.134 6 17 9.13401 17 13C17 16.866 20.134 20 24 20Z"
                fill="none"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 40.8V42H42V40.8C42 36.3196 42 34.0794 41.1281 32.3681C40.3611 30.8628 39.1372 29.6389 37.6319 28.8719C35.9206 28 33.6804 28 29.2 28H18.8C14.3196 28 12.0794 28 10.3681 28.8719C8.86278 29.6389 7.63893 30.8628 6.87195 32.3681C6 34.0794 6 36.3196 6 40.8Z"
                fill="none"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
          ></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'app' }"
        >
          <router-link to="/app">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="{ 'aside-icon': $route.name === 'app' }"
            >
              <path
                d="M23 40H7C5.34315 40 4 38.6569 4 37V11C4 9.34315 5.34315 8 7 8H41C42.6569 8 44 9.34315 44 11V25.8824"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 11C4 9.34315 5.34315 8 7 8H41C42.6569 8 44 9.34315 44 11V20H4V11Z"
                fill="none"
                stroke="#000"
                stroke-width="4"
              />
              <path
                d="M34 33L30 37L34 41"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M40 33L44 37L40 41"
                stroke="#000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                r="2"
                transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 10 14)"
                fill="#000"
              />
              <circle
                r="2"
                transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 16 14)"
                fill="#000"
              /></svg
          ></router-link>
        </div>
        <div class="logo-box">
        </div>
      </div>
    </aside>
    <main>
      <transition name="fade-right" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#container {
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

.aside-icon {
  path {
    stroke: #fff;
  }
}

aside {
  width: 100px;
  background-color: #f4ebe4;
  border-radius: 13px;

  .link-box {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 13px;

    .avatar-box,
    .logo-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      box-sizing: border-box;
      flex: 2;
    }

    .route-link {
      background-color: #fff;
      border-radius: 100px;
      min-width: 50px;
      min-height: 50px;
      margin: 10px 0;
      transition: background-color 0.5s ease;

      a {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}

.active-black {
  background-color: #000 !important;
}

main {
  flex: 1;
  padding-left: 20px;
  transition: background-color 0.5s;
  box-sizing: border-box;
}
</style>
