import axios from "axios";
// import qs from "qs";
import { Loading } from "element-ui";

let loading;
let loadingNum = 0; //  内存中正在请求的数量

function startLoading() {
  if (loadingNum == 0) {
    loading = Loading.service({
      lock: true,
      text: "正在努力码砖...",
      background: "rgba(255,255,255,0.5)",
      target: document.querySelector(".content"),
    });
  }
  loadingNum++;
}

function endLoading() {
  //  关闭加载动画
  loadingNum--;
  if (loadingNum <= 0) {
    loading.close();
  }
}

/*
	baseURL：基本地址
	所有发送到后端的请求都会经过这里，然后在访问的url前拼接上baseURL，从而获得真正的访问地址
	举例：  url: /request
		   经过这里后，与baseURL进行拼接，变成：baseURL/request
	所以在使用封装的Axios时，url只需写baseURL后的地址即可
*/
const service = axios.create({
//   baseURL: process.env.API_ROOT, //  基本地址
  responseType: "json",
  withCredentials: true, //  允许跨域设置，不然可能因为拿不到cookie而报错
//   headers: {
//     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//   },
  timeout: 1000 * 10, //  设置请求超时时间，单位为毫秒
});

/* 
	环境的切换，可以根据不同的环境设置不同的请求基本地址
	根据需要进行修改即可
*/
// if (process.env.NODE_ENV == "development") {
//   service.defaults.baseURL = "http://202.181.24.237:8080";
// } else if (process.env.NODE_ENV == "debug") {
//   service.defaults.baseURL = "http://202.181.24.237:8080";
// } else if (process.env.NODE_ENV == "production") {
//   service.defaults.baseURL = "http://202.181.24.237:8080";
// }

//  请求拦截器
service.interceptors.request.use(
  (config) => {
    startLoading(); //  打开加载动画

    // if (config.meth === "post" && !(config.data instanceof FormData)) {
    //   config.data = qs.stringify(config.data, {
    //     arrayFormat: "repeat", //  后端要求传数组的时候做的设置
    //   });
    // }

    // 将token放到请求头中发送给服务器
    // if (window.localStorage.getItem("token")) {
    //   config.headers.common["Access-Token"] =
    //     window.localStorage.getItem("token");
    // }

    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    endLoading(); //  关闭加载动画

    /*  
        	如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        	否则的话抛出错误 
        */
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },

  /* 
    	服务器状态码不是2开头的的情况
        要和后台统一的错误状态码    
        然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    */
  (error) => {
    endLoading(); //  关闭加载动画
    if (error && error.response && error.response.status) {
      return Promise.reject(error);
    }
  }
);

export default service;
