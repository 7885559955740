import { MessageBox } from "element-ui";
/**
 * 根据HTTP状态码解析错误信息
 * @param {number} status - HTTP响应状态码
 * @returns {string} 错误信息
 */
function getErrorMessage(status) {
    switch (status) {
        case 401:
            return '未授权，请重新登录';
        case 403:
            return '拒绝访问';
        case 404:
            return '网络请求不存在';
        case 405:
            return '请求方法不允许';
        case 408:
            return '请求超时，请稍后再试';
        case 429:
            return '服务器繁忙，请稍后再试';
        case 500:
            return '服务器端错误';
        case 501:
            return '网络未实现';
        case 502:
            return '网络错误';
        case 503:
            return '服务不可用';
        case 504:
            return '网络超时';
        default:
            return '服务器内部错误，请稍后再试';
    }
}

/**
 * 统一处理axios的错误响应
 * @param {Object} error - axios错误对象
 */
export function handleError(error) {
    if (error.response) {
        // 请求已发出，但服务器响应的状态码不在2xx范围内
        const errorMessage = getErrorMessage(error.response.status);
        MessageBox({
            message: errorMessage,
            type: 'error',
            title: '提示'
        }).catch(() => {
            
        })
    } else if (error.request) {
        // 请求已发出，但没有收到响应
        console.error('The request was made but no response was received', error.request);
        MessageBox({
            message: '请求失败，请检查网络连接',
            type: 'error',
            title: '提示'
        });
    } else {
        // 其他错误
        console.error('Error', error.message);
        MessageBox({
            message: '系统错误，请联系管理员',
            type: 'error',
            title: '提示'
        });
    }
}

export default {
    handleError
}