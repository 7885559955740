import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import {gsap} from "gsap";
import qs from "qs"
import service from "./utils/request";
import {handleError} from "./utils/errorHandler";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$gsap = gsap;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$service = service;
Vue.prototype.$handleError = handleError;


axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
